import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    listItem: {
      "& > h4": {
        display: "flex",
        justifyContent: "center",
        background: grey[100],
        padding: theme.spacing(0.5, 0),
      },
      "& h1, & h2, & > div": {
        margin: theme.spacing(2, 0),
        fontSize: 35,
      },
    },
    aditionalInfo: {
      "& h1": {
        fontSize: 20,
      },
    },
    big: {
      fontSize: 35,
    },
    small: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      listItem: {
        "& > h4": {
          "& span": {
            fontSize: 20
          }
        }
      }
    },
  };
});
