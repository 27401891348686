import React, { FC, useEffect } from "react";
import { useStyles } from "./header.style";
import { useTranslator } from "localization";
import { Menu, FavoriteMenu } from "./components";
import { Link, useLocation } from "react-router-dom";
import { isWidthDown, isWidthUp, withWidth } from "@material-ui/core";
import clsx from "clsx";
import { Logo } from "components/shared";
import { connect } from "react-redux";
import { IAppState } from "store/state/reducers";
import { StaticInfoActions } from "store/actions/static-info.actions";
import { IHeaderInfo } from "views/about/types";

export interface IHeader {
  width: any;
  getHeaderInfo(): void;
  info: IHeaderInfo;
}

const Header: FC<IHeader> = ({ width, getHeaderInfo, info }) => {
  const lang = useTranslator();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    getHeaderInfo();
  }, []);

  return (
    <div className={clsx(classes.root, location.pathname !== "/" && classes.marginBottom)}>
      <div>
        <Link to="/">
          <Logo name={info.name} img={info.logo} />
        </Link>
      </div>
      {isWidthUp("md", width) && <Menu variant="web" />}
      <div className={classes.favMenuContainer}>
        {/* <FavoriteMenu list={[]} count={0} /> */}
        {isWidthDown("sm", width) && <Menu variant="mobile" />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  info: state.staticInfo.headerInfo,
  loading: state.items.loading,
});
export default withWidth()(connect(mapStateToProps, StaticInfoActions)(Header));
