import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: 400,
      "& > div": {
        position: "relative",
        padding: theme.spacing(0, 6, 8, 6),
      },
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4, 0, 2, 0),
      "& h1": {
        fontSize: 45,
      },
    },
    content: {

    },
    pattern: {
      height: 80,
    },
    branchList: {
      "& .MuiListItemText-multiline": {
        margin: 0
      },
      "& .MuiListItem-gutters": {
        padding: theme.spacing(.5, 2),
      },
      "& .MuiListSubheader-root": {
        fontSize: 20,
        color: theme.palette.color.black
      },
      "& .MuiListItemText-secondary": {
        display: "flex",
        alignItems: "center",
        color: theme.palette.color.black,
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          left: -4,
          position: "relative",
        }
      }
    },
    [theme.breakpoints.down("xl")]: {
      root: {
        minHeight: 600,
      },
      pattern: {
        height: 100,
      },
    },
    [theme.breakpoints.down("lg")]: {
      root: {
        minHeight: 400,
      },
      pattern: {
        height: 80,
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      pattern: {
        height: 50,
      },
      root: {
        marginBottom: `${theme.spacing(0)}px !important`,
        "& > div": {
          position: "relative",
          padding: theme.spacing(0, 2, 8, 2),
        },
      },
    },
  };
});
