import { createActionFactory } from "helpers/actionFactory";

const factory = createActionFactory("ITEMS");

export const getItems = factory.create("GET_ITEMS");
export const getItemsAsync = factory.createAsync("GET_ITEMS_ASYNC");

export const getItem = factory.create("GET_ITEM");
export const getItemAsync = factory.createAsync("GET_ITEM_ASYNC");

export const getColors = factory.create("GET_COLORS");
export const getColorsAsync = factory.createAsync("GET_COLORS_ASYNC");

export const getMainItems = factory.create("GET_MAIN_ITEMS");
export const getMainItemsAsync = factory.createAsync("GET_MAIN_ITEMS_ASYNC");

export const getCategs = factory.create("GET_CATEGS");
export const getCategsAsync = factory.createAsync("GET_CATEGS_ASYNC");

export const ItemActions = {
    getItems,
    getItemsAsync,
    getItem,
    getItemAsync,
    getColors,
    getColorsAsync,
    getMainItems,
    getMainItemsAsync,
    getCategs,
    getCategsAsync
};
