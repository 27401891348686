import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      padding: theme.spacing(1, 1, 2, 1),
      display: "flex",
      justifyContent: "flex-end"
    },
    slick: {
      width: "100%",
      position: "relative"
    },
    title: {},
    badge: {
      border: `1px solid ${grey[400]}`,
      padding: "0 !important",
      borderRadius: "20px !important",
      transition: "all 0.3s all 0s",
      width: 80,
      "& .MuiButton-label": {
        width: "100%",
        justifyContent: "unset",
        position: "relative"
      }
    },
    color: {
      height: 35,
      width: 35,
      borderRadius: 20,
      border: `2px solid ${grey[100]}`,
    },
    activeBadge: {
      "& $color": {
        border: "none"
      },
      "& $count": {
        background: "#fff",
        borderRadius: 4,
        padding: theme.spacing(0, .5),
      }
    },
    count: {
      position: "absolute",
      right: 20
    },
    arrow: {
      "& button": {
        padding: 0,
        boxShadow: "-1px 0 6px rgb(0 0 0 / 20%)",
        height: "100%",
        borderRadius: 20,
      },
    },
    rightArrow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    selectContainer: {
      paddingLeft: theme.spacing(2),
      "& .MuiOutlinedInput-root": {
        width: "100%"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: 1
      }
    },
    select: {
      width: "100%",
      padding: 12
    },
    openButton: {
      background: "white",
      color: theme.palette.primary.main,
      padding: 0,
      marginLeft: theme.spacing(2),
      "& svg": {
        height: 33,
        width: 33
      }
    },
    mobileRoot: {
      display: "flex",
      padding: theme.spacing(2, 2, 1, 0),
    },
    modalColorsContainer: {
      padding: theme.spacing(1),
      background: "rgba(255,255,255,0.2)",
      height: "100%"
    },
    modalButtons: {
      position: "absolute",
      bottom: theme.spacing(4),
      left: theme.spacing(-12),
      background: "rgba(255,255,255,0.2)",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2)
    },
    clearFilterButton: {
      borderRadius: 100,
      minHeight: 40,
      minWidth: 40,
      padding: theme.spacing(1.2),
      color: theme.palette.primary.main,
      "& .MuiBadge-badge": {
        height: 20,
        width: 20,
        fontSize: 18,
        background: theme.palette.error.main
      },
      "& svg": {
        height: 33,
        width: 33
      }
    },
    modalPaper: {
      boxShadow: "none",
      backgroundColor: "transparent !important",
      overflowY: "unset !important"
    } as any,
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      slick: {
        width: "80%",
      },
      arrow: {
        "& button": {
          height: 30,
          minWidth: "100%"
        },
      },
      badge: {
        width: 70,
        height: 30,
      },
      color: {
        height: 30,
        width: 30,
      } as any,
    },
    [theme.breakpoints.down("xs")]: {
      badge: {
        marginBottom: theme.spacing(1)
      }
    },
  };
});
