import { all } from "redux-saga/effects";
import { ItemSaga } from "../sagas/item.saga"
import { StaticActionSaga } from "../sagas/static-info.saga"

export default function* rootSaga() {
  yield all([
    ItemSaga(),
    StaticActionSaga()
  ]);
}
