import { IconButton, SwipeableDrawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useTranslator } from "localization";
import React, { FC, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMenu } from "./menu-list";
import { useStyles } from "./menu.style";
import bottomPattern from "assets/bottomPattern.png";

export interface IMenu {
  variant: "mobile" | "web" | "vertical";
  className?: string;
  navigator?: boolean;
}

const Menu: FC<IMenu> = ({ variant, className, navigator = true }) => {
  const lang = useTranslator();
  const classes = useStyles();
  const menuList = useMenu();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const menu = (direction: "vertical" | "horizontal", nav: boolean) => {
    return (
      <ul className={clsx(classes.menu, direction === "vertical" && classes.vertical, className)}>
        {menuList.map((m) => (
          <li key={m.link}>
            <NavLink onClick={() => setOpen(false)} to={m.link}>
              {m.label}
            </NavLink>
            {location.pathname === m.link && nav && <img src={bottomPattern} draggable={false} alt="sectionPattern" />}
          </li>
        ))}
      </ul>
    );
  };

  const mobileMenu = () => {
    return (
      <>
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          classes={{ paper: classes.drawerPaper }}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          {menu("vertical", true)}
        </SwipeableDrawer>
      </>
    );
  };

  return variant === "web"
    ? menu("horizontal", navigator)
    : variant === "mobile"
    ? mobileMenu()
    : menu("vertical", navigator);
};

export default Menu;
