import React, { FC } from "react";
import { useStyles } from "./section-header.style";
import { useTranslator } from "localization";
import bottomPattern from "assets/bottomPattern.png";

export interface ISectionHeader {
  text: string;
}

export const SectionHeader: FC<ISectionHeader> = ({ text }) => {
  const lang = useTranslator();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>{text}</h1>
      <img draggable={false} src={bottomPattern} alt="bottom pattern" />
    </div>
  );
};
