import { connectRouter, RouterState } from "connected-react-router";
import { combineReducers } from "redux";
import items, { IItemReducer } from "../reducers/item.reducer"
import staticInfo, { StaticActionReducer } from "../reducers/static-info.reducer"

const createReducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    items,
    staticInfo
  });

export interface IAppState {
  items: IItemReducer,
  staticInfo: StaticActionReducer
}

export default createReducers;
