import React, { FC } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useStyles } from "./slick-slider.style";
import clsx from "clsx";
import { isWidthDown, isWidthUp, withWidth } from "@material-ui/core";

export interface ISlickSlider {
  sliderRef?(ref: Slider | null): void;
  width?: any;
}

const SlickSlider: FC<ISlickSlider & Settings> = ({ children, sliderRef, width, ...rest }) => {
  const classes = useStyles();

  const getCount = () => {
    if (isWidthDown("sm", width)) {
      return 5;
    } else if (isWidthDown("md", width)) {
      return 6;
    } else if (isWidthDown("lg", width)) {
      return 8;
    } else return 9;
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    //centerMode: true,
    speed: 500,
    slidesToShow: getCount(),
    slidesToScroll: getCount(),
    arrows: false,
    className: clsx(classes.slick),
    responsive: [],
    ...rest,
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {children}
    </Slider>
  );
};

export default withWidth()(SlickSlider);
