import React, { FC } from "react";
import { useStyles } from "./card-style";
import { useTranslator } from "localization";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { IconButton, isWidthDown, withWidth } from "@material-ui/core";
import { isWidthUp } from "@material-ui/core";
import { stringCutter } from "helpers/functions";

interface IAos {
  slide?: string;
  delay?: number;
  offset?: number;
  duration?: number;
}

export interface ICardPage {
  img: string;
  colors?: string[] | string;
  about?: string;
  name?: string;
  code?: string;
  sizes?: string;
  width: any;
  length?: number;
  aos?: IAos;
  category?: any;
  onClick(): void;
}

const Card: FC<ICardPage> = ({ img, colors = [], about, code, name, width, aos, category, onClick }) => {
  const lang = useTranslator();
  const classes = useStyles();

  const colorContent = () => {
    if (Array.isArray(colors) && colors?.length > 0) {
      return (
        <div className={classes.colorContainer}>
          {colors?.map((c, i) => (
            <div key={i} style={{ backgroundColor: c }}></div>
          ))}
        </div>
      );
    } else if (typeof colors === "string") {
      return (
        <div className={classes.colorContainer}>
          <div style={{ backgroundColor: colors }}></div>
        </div>
      );
    }
  };

  return (
    <div
      className={classes.root}
      data-aos={aos?.slide}
      data-aos-offset={aos?.offset}
      data-aos-delay={aos?.delay}
      data-aos-duration={aos?.duration}
      onClick={onClick}
    >
      <div className={classes.img}>
        <img src={img} alt="oboy" />
      </div>
      <div className={classes.info}>
        {code && (
          <h3>
            {code} {category ? ` ⦁ ${category}` : ""}
          </h3>
        )}
        {about && isWidthUp("sm", width) && (
          <span>{stringCutter(about, isWidthDown("sm", width) ? 20 : isWidthDown("md", width) ? 55 : undefined)}</span>
        )}
      </div>
      {colors && colorContent()}
      {/* <IconButton className={classes.favButton}>
        <FavoriteBorderIcon />
      </IconButton> */}
    </div>
  );
};

export default withWidth()(Card);
