import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { CornerPattern } from "components/shared";
import { useTranslator } from "localization";
import React, { FC } from "react";
import { useStyles } from "./section-item.style";

export interface ISectionItem {
  header: string;
  text: string;
  imgLeft: string;
  imgRight: string;
}

export const SectionItem: FC<ISectionItem> = ({ header, imgLeft, imgRight, text }) => {
  const lang = useTranslator();
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} lg={6} style={{ backgroundImage: `url(${imgLeft})` }} className={clsx(classes.itemContent)}>
        <div data-aos="zoom-out">
          <h2>{header}</h2>
          <div>{text}</div>
          <CornerPattern />
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={6} style={{ backgroundImage: `url(${imgRight})` }} className={clsx(classes.item)}></Grid>
      </Hidden>
    </>
  );
};
