import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    logo: {
      fontFamily: `'Great Vibes', cursive`,
      fontSize: 30,
      color: theme.palette.color.black,
      fontWeight: "normal",
    },
    logoImg: {
      height: 70,
      width: 140,
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      logoImg: {
        height: 40,
        width: 100,
      },
    },
  };
});
