import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import React, { FC } from "react";
import { useStyles } from "./list-item.style";

export interface IListItem {
  title: string;
  text?: string | JSX.Element;
  bgColor?: string;
  textSize?: "big" | "small";
  hidden?: boolean;
}

export const ListItem: FC<IListItem> = ({ bgColor, text, title, textSize = "big", hidden = false }) => {
  const classes = useStyles();

  const useClasses = makeStyles((theme) => {
    return {
      itemBack: {
        position: "relative",
        "&::after": {
          content: "''",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: bgColor || "black",
          opacity: 0.2,
          transition: "all .2s ease-in-out 0s",
        },
        "& span": {
          zIndex: 1,
        },
      },
    };
  });
  const after = useClasses();

  return (
    hidden ? <></> :
    <div className={clsx(classes.listItem)}>
      <h4 className={after.itemBack}>
        <span>{title}</span>
      </h4>
      {typeof text === "string" ? <h1 className={classes[textSize]}>{text}</h1> : text}
    </div>
  );
};
