import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    info: {
      padding: theme.spacing(0, 6, 2, 6),
    },
    code: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      alignItems: "center",
      "& img": {
        height: 40,
        position: "absolute",
      },
      "& img:nth-child(1)": {
        left: 0,
        transform: "rotate(180deg)",
      },
      "& img:nth-child(2)": {
        right: 0,
      },
      "& h1": {
        fontSize: 50,
      },
    },
    listItem: {
      "& > h4": {
        display: "flex",
        justifyContent: "center",
        background: grey[100],
        padding: theme.spacing(0.5, 0),
      },
      "& h1, & h2, & > div": {
        margin: theme.spacing(2, 0),
        fontSize: 35,
      },
    },
    aditionalInfo: {
      "& h1": {
        fontSize: 20,
      },
    },
    accessuars: {
      "& > .MuiChip-root": {
        marginRight: theme.spacing(2),
        background: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 17,
        boxShadow: "-1px 0 6px rgb(0 0 0 / 50%)"
      }
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      info: {
        padding: theme.spacing(0, 0, 2, 0),
      },
      code: {
        marginTop: theme.spacing(3),
        "& img": {
          height: 30,
          position: "absolute",
        },
        "& h1": {
          fontSize: 40,
        },
      }
    },
  };
});
