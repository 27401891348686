import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    slick: {
      "& .slick-slide": {
        position: "relative"
      },
      "& .slick-track": {
        marginLeft: 0,
      },
      "& .slick-list": {},
      "& .slick-prev:before, & .slick-next:before": {
        color: theme.palette.common.white,
        fontSize: 35,
      },
      "& .slick-arrow": {
        zIndex: 1,
        opacity: 0.6,
        "&:hover": {
          opacity: 1,
        },
      },
      "& .slick-next": {
        right: 20,
      },
      "& .slick-prev": {
        left: 5,
      },
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  };
});
