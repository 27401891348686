import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    menu: {
      listStyle: "none",
      listStyleType: "none",
      display: "flex",
      "& li": {
        position: "relative",
        "& a": {
          padding: theme.spacing(1, 2),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: grey[900],
          fontWeight: "bold",
          fontSize: 18,
        },
        "& img": {
          position: "absolute",
          height: 15,
          left: "50%",
          transform: "translate(-50%)",
          zIndex: 1
        },
      },
    },
    vertical: {
      display: "block",
      "& li": {
        "& a": {
          padding: theme.spacing(2, 5),
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          color: grey[900],
          fontWeight: "bold",
          fontSize: 18,
        },
      },
    },
    [theme.breakpoints.down("xl")]: {
      menu: {
        "& li": {
          "& a": {
            padding: theme.spacing(3),
            fontSize: 22,
          },
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      menu: {
        "& li": {
          "& a": {
            padding: theme.spacing(2),
            fontSize: 18,
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      menu: {
        "& li": {
          "& a": {
            padding: theme.spacing(2, 2, 0.5),
            justifyContent: "center"
          },
          "& img": {
            height: 10,
            width: "90%"
          },
        },
      },
      drawerPaper: {
        padding: theme.spacing(1, 3)
      },
    },
  };
});
