import { Grid, isWidthDown, isWidthUp, withWidth } from "@material-ui/core";
import oboy from "assets/aboy.jpg";
import oboy1 from "assets/aboy1.jpg";
import ceiling from "assets/ceiling.jpg";
import ceiling1 from "assets/ceiling1.jpg";
import coverStatic from "assets/cover.jpg";
import moydadir from "assets/moydadir.jpg";
import moydadir2 from "assets/moydadir2.jpg";
import { CornerPattern, Loader } from "components/shared";
import { useTranslator } from "localization";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { ItemActions } from "store/actions/item.actions";
import { IAppState } from "store/state/reducers";
import { ICardListMain, SectionItem } from "./components";
import { useStyles } from "./main.style";
import { IMainItems } from "./types";

export interface IMain {
  width: any;
  maniItems: IMainItems;
  getMainItems(count: number): void;
  siteName: string;
  defis: string;
  cover: string;
  loading: boolean;
}

const Main: FC<IMain> = ({ width, getMainItems, maniItems, defis, siteName, cover, loading }) => {
  const lang = useTranslator();
  const classes = useStyles();

  useEffect(() => {
    getMainItems(isWidthUp("sm", width) && isWidthDown("sm", width) ? 3 : 4);
  }, []);

  return (
    <Loader loading={loading}>
      <section>
        <div className={classes.cover} style={{ backgroundImage: `url(${cover || coverStatic})` }}>
          <div data-aos="flip-up">
            <h1>{siteName}</h1>
            <CornerPattern />
            <span>{defis}</span>
          </div>
        </div>
        <Grid container>
          <SectionItem
            text={maniItems.wallpapers?.info?.about}
            header="Oboylar"
            imgLeft={maniItems.wallpapers?.info?.leftPhoto || oboy}
            imgRight={maniItems.wallpapers?.info?.rightPhoto || oboy1}
          />
          <ICardListMain type="wallpaper" header="Oboylar" list={maniItems.wallpapers?.cards} />
          <SectionItem
            text={maniItems.moydaders?.info?.about}
            header="Moydadırlar"
            imgLeft={maniItems.moydaders?.info?.leftPhoto || moydadir}
            imgRight={maniItems.moydaders?.info?.rightPhoto || moydadir2}
          />
          <ICardListMain type="moydader" header="Moydadırlar" list={maniItems.moydaders?.cards} />
          <SectionItem
            text={maniItems.ceilings?.info?.about}
            header="Asma Tavanlar"
            imgLeft={maniItems.ceilings?.info?.leftPhoto || ceiling}
            imgRight={maniItems.ceilings?.info?.rightPhoto || ceiling1}
          />
          <ICardListMain type="ceiling" header="Asma Tavanlar" list={maniItems.ceilings?.cards} />
        </Grid>
      </section>
    </Loader>
  );
};

const mapStateToProps = (state: IAppState) => ({
  info: state.staticInfo.headerInfo,
  maniItems: state.items.mainItems,
  loading: state.items.loading,
  siteName: state.staticInfo.headerInfo.name,
  defis: state.staticInfo.headerInfo.defis,
  cover: state.staticInfo.headerInfo.cover,
});
export default withWidth()(connect(mapStateToProps, ItemActions)(Main));
