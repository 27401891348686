import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: "relative",
            bottom: -6,
            overflow: "hidden",
            "& img": {
                position: "relative",
                width: 30
            }
        },
        topLine: {
            position: "absolute",
            whiteSpace: "nowrap",
            left: 21
        },
        bottomLine: {
            whiteSpace: "nowrap",
        },
        [theme.breakpoints.down("xl")]: {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.down("xs")]: {},
    };
});