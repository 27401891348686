import React, { FC } from "react";
import { useStyles } from "./layout.style";
import { LayoutRoutes } from "routes/layout-routes";
import { Footer, Header } from "components/layout";
import { WallLine } from "components/layout/footer/components";
import { useLocation, useParams } from "react-router-dom";

export interface ILayout {}

export const Layout: FC<ILayout> = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams()

  return (
    <div className={classes.root}>
      <div>
        <header>
          <Header />
        </header>
        <main className={classes.main}>
          <LayoutRoutes />
        </main>
      </div>
      <footer className={classes.footerContainer}>
        {location.pathname === "/" && <WallLine />}
        <div className={classes.footer}>
          <Footer />
        </div>
      </footer>
    </div>
  );
};
