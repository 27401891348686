import React, { FC } from "react";
import { useTranslator } from "localization";
import { useStyles } from "./jodit.style";
import clsx from "clsx";

export interface IJoditContainer {
  content: string;
  className?: string;
}

export const JoditContainer: FC<IJoditContainer> = ({ content, className }) => {
  const lang = useTranslator();
  const classes = useStyles();

  return <div className={clsx(classes.root, className)} dangerouslySetInnerHTML={{ __html: content }} />;
};
