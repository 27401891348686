import React, { FC } from "react";
import { useStyles } from "./card-list.style";
import { useTranslator } from "localization";
import Card from "../card";
import { Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import bottomPattern from "assets/bottomPattern.png";
import { omit } from "helpers/functions";
import { Loader } from "../loader";
import { ICategory, IItems } from "views/items/types";
import { useHistory, useLocation } from "react-router";
import { links } from "routes/links";

export interface ICardList {
  list: IItems;
  paginate?: boolean;
  count?: number;
  centered?: boolean;
  urlStart?: string;
  size?: number;
  onPaginate?(page: number): void;
  categs?: ICategory[];
}

const CardList: FC<ICardList> = ({
  list,
  paginate,
  count,
  categs,
  centered,
  urlStart,
  size = 12,
  onPaginate = () => {},
}) => {
  const lang = useTranslator();
  const classes = useStyles();
  const delayList = [100, 150, 200, 250, 300, 350, 400];
  const location = useLocation();
  const link =
    location.pathname?.substr(1, location.pathname?.length) || urlStart;
  const history = useHistory();

  const handleClick = (id: number, color?: string) => {
    if (color) {
      history.push(`/${link}/${id}/${color?.slice(1, color?.length)}`);
    } else {
      history.push(`/${link}/${id}`);
    }
  };

  return (
    <Loader loading={false}>
      <article className={classes.root}>
        <Grid container justifyContent={centered ? "center" : "flex-start"}>
          {list?.cards?.map(({ width, length, id, ...item }, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={4}
              md={3}
              className={classes.cardContainer}
            >
              <Card
                onClick={() => handleClick(id, item.currentColor)}
                length={length}
                img={item.photo}
                {...omit(item, "width")}
                category={categs?.filter(c => c.id === item.categoryId)[0]?.name}
                aos={{
                  slide: "zoom-in",
                  delay:
                    delayList[Math.floor(Math.random() * delayList.length)],
                  offset: 40,
                  duration: 500,
                }}
                {...item}
              />
            </Grid>
          ))}
          {paginate && (
            <Grid item xs={12} className={classes.pagination}>
              <img src={bottomPattern} draggable={false} alt="sectionPattern" />
              <Pagination
                count={Math.ceil(list?.totalCount / size)}
                onChange={(e, p) => onPaginate(p)}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          )}
        </Grid>
      </article>
    </Loader>
  );
};

export default CardList;
