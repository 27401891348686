import { createTheme } from "@material-ui/core/styles";
import { grey, red } from "@material-ui/core/colors";

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
        },
        a: {
          cursor: "pointer",
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0 1px 3px rgb(43 45 55 / 10%)",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
    },
  },
  props: {
    MuiSelect: {},
  },
  palette: {
    primary: {
      main: "#605FDE",
      light: "#bfbff2",
      dark: "#43439b",
      "100": "#f5f6fa",
    },
    warning: {
      main: "#FDB450",
      light: "#FDC477",
      dark: "#FC9305",
    },
    error: {
      main: red[800],
      light: red[600],
    },
    color: {
      white: grey[300],
      black: grey[900],
    },
  },
});

export default theme;
