import React, { FC } from "react";
import { useStyles } from "./corder-pattern.style";
import { useTranslator } from "localization";
import clsx from "clsx";
import corner from "assets/corner.png";
import ex from "assets/ex.png";

type Img = "wallpaper" | "leaf";

export interface ICornerPattern {
  image?: Img;
  className?: string;
}

export const CornerPattern: FC<ICornerPattern> = ({ image = "wallpaper", className }) => {
  const lang = useTranslator();
  const classes = useStyles();

  const getImg = (img: Img) => {
    switch (img) {
      case "wallpaper":
        return corner;
      case "leaf":
        return ex;
      default:
        return corner;
    }
  };

  return (
    <>
      <img draggable={false} src={getImg(image)} className={clsx(className, classes.img, classes.tl)} alt="line" />
      <img draggable={false} src={getImg(image)} className={clsx(className, classes.img, classes.tr)} alt="line" />
      <img draggable={false} src={getImg(image)} className={clsx(className, classes.img, classes.bl)} alt="line" />
      <img draggable={false} src={getImg(image)} className={clsx(className, classes.img, classes.br)} alt="line" />
    </>
  );
};
