import React, { createRef, FC, useRef, useState } from "react";
import { useTranslator } from "localization";
import { useStyles } from "./slider.style";
import { ICard, IItemsField } from "views/items/types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Button } from "@material-ui/core";
//@ts-ignore
import { ColorExtractor } from "react-color-extractor";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ReactPictureTagger from "components/shared/picture-tagger";

export interface ISlider {
  item: ICard;
  currItem: IItemsField;
  onThumbClick(item: IItemsField): void;
  isSlider: boolean;
  itemToShow?: number;
  width?: number;
}

export const Slider: FC<ISlider> = ({ currItem, item, onThumbClick, isSlider, itemToShow = 3, width = 190 }) => {
  const lang = useTranslator("main");
  const classes = useStyles();
  const items = item?.items;
  const [colorPalette, setColorPalette] = useState<string[]>([]);
  const sliderContainer = useRef<HTMLDivElement>(null);

  const useDynamicStyle = makeStyles((theme) => {
    return {
      dynamicColor: {
        background: currItem?.color || "#fafafa",
        "&:before": {
          background: items ? items[items?.indexOf(currItem) - 1]?.color || "#fafafa" : "#fafafa",
        },
        "&:after": {
          background: items ? items[items?.indexOf(currItem) - 2]?.color || "#fafafa" : "#fafafa",
        },
      },
    };
  });
  const dynmicClasses = useDynamicStyle();

  const renderPalette = (img: string) => {
    return (
      <>
        <ColorExtractor src={img} getColors={(colors: any) => setColorPalette(colors)} />
        <div className={classes.paletContainer}>
          <p>{lang.imgColorTonnes}</p>
          {colorPalette?.map((c, i) => (
            <div key={i} style={{ background: c }}></div>
          ))}
        </div>
      </>
    );
  };

  const scroll = (pos: "back" | "forward") => {
    const el = sliderContainer.current;

    if (el && items) {
      if (pos === "back" && items.indexOf(currItem) <= items.length - 3) {
        el.scrollLeft = el.scrollLeft - width;
      } else if (pos === "forward" && items.indexOf(currItem) >= 2) {
        el.scrollLeft = el.scrollLeft + width;
      }
    }
  };

  const handlePhotoChange = (pos: "back" | "forward") => {
    scroll(pos);
    if (items) {
      if (pos === "back") {
        if (items.indexOf(currItem) !== 0) {
          onThumbClick(items[items?.indexOf(currItem) - 1]);
        }
      } else {
        if (items.indexOf(currItem) !== items.length - 1) {
          onThumbClick(items[items?.indexOf(currItem) + 1]);
        }
      }
    }
  };

  const renderArrows = () => {
    return (
      <>
        <Button onClick={() => handlePhotoChange("back")} className={clsx(classes.arrow, classes.leftArrow)}>
          <ArrowBackIosIcon />
        </Button>
        <Button onClick={() => handlePhotoChange("forward")} className={clsx(classes.arrow, classes.rightArrow)}>
          <ArrowForwardIosIcon />
        </Button>
      </>
    );
  };

  return (
    <div className={classes.photo}>
      {isSlider ? (
        <div>
          <div
            className={clsx(classes.sliderCover, dynmicClasses.dynamicColor)}
            style={{ background: currItem?.color }}
          >
            {renderPalette(currItem ? currItem.photo : item.photo)}
            {renderArrows()}
            <img src={currItem?.photo} alt="img" />
          </div>
          <div className={classes.thumbNails} style={{ width: itemToShow * width }} ref={sliderContainer}>
            <div style={{ width: width * (items ? items.length : 1) }}>
              {items?.map((item, i) => {
                const arr = item?.photo?.split(".") || [];
                return (
                  <Button
                    onClick={() => onThumbClick(item)}
                    key={i}
                    className={clsx(item.photo === currItem?.photo && classes.activeThumb)}
                  >
                    <img
                      src={`${arr?.slice(0, arr?.length - 1).join(".")}_260x190.${arr[arr?.length - 1]}`}
                      alt="img"
                      key={i}
                      draggable={false}
                    />
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.sliderCover}>
          {renderPalette(item.photo)}
          <img src={item.photo} alt={item.code} />
          {/* <ReactPictureTagger
            imageSrc={item.photo}
            tags={[{ top: 128, left: 5, width: 180, height: 190, name: "Rizzo" }]}
            showTags={true}
            tagsUpdated={console.log} 
          />*/}
        </div>
      )}
    </div>
  );
};
