import { handleActions } from "redux-actions";
import { ICard, ICategory, IFilterColor, IItems } from "views/items/types";
import { IMainItems } from "views/main/types";
import { getItemsAsync, getItemAsync, getColorsAsync, getMainItemsAsync, getCategsAsync } from "../actions/item.actions";

export interface IItemReducer {
    items: IItems;
    mainItems: IMainItems;
    categs: ICategory[];
    item: ICard;
    colors: IFilterColor[];
    loading: boolean
}

const initialState: IItemReducer = {
    items: {} as IItems,
    item: {} as ICard,
    categs: [],
    mainItems: {} as IMainItems,
    colors: [],
    loading: false
};

export default handleActions(
    {
        [getCategsAsync.started]: (state) => ({ ...state, loading: true }),
        [getCategsAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getCategsAsync.success]: (state, action: any) => ({
            ...state,
            categs: action.payload.data,
            loading: false,
        }),
        [getMainItemsAsync.started]: (state) => ({ ...state, loading: true }),
        [getMainItemsAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getMainItemsAsync.success]: (state, action: any) => ({
            ...state,
            mainItems: action.payload,
            loading: false,
        }),
        [getItemsAsync.started]: (state) => ({ ...state, loading: true }),
        [getItemsAsync.failed]: (state) => ({ ...state, items: [], loading: false, }),
        [getItemsAsync.success]: (state, action: any) => ({
            ...state,
            items: action.payload.data,
            loading: false,
        }),
        [getColorsAsync.started]: (state) => ({ ...state, loading: true }),
        [getColorsAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getColorsAsync.success]: (state, action: any) => ({
            ...state,
            colors: action.payload.data,
            loading: false,
        }
        ),
        [getItemAsync.started]: (state) => ({ ...state, loading: true }),
        [getItemAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getItemAsync.success]: (state, action: any) => ({
            ...state,
            item: action.payload.data,
            loading: false,
        }),
    },
    initialState
);
