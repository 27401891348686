export const stringCutter = (text: string | undefined, end: number | undefined, start?: number) => {
  const startIndex = start || 0;
  const endIndex = end || text?.length || 0;
  return text ? (text.length > endIndex ? `${text.slice(startIndex, endIndex)}...` : text) : "";
};

export const getLimitOffset = (page: number, lmt: number) => {
  let offset = (page - 1) * lmt;
  let limit = lmt;
  return { offset, limit };
};

export const omit = (obj: {}, ...rest: string[]) => {
  for (let key of rest) {
    if (obj[key as keyof typeof obj]) {
      delete obj[key as keyof typeof obj];
    }
  }

  return obj;
};
