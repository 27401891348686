import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
      "& > div": {
        flex: "1 0 auto",
      },
      "& article": {
        margin: "0 auto",
        maxWidth: 1280,
        padding: theme.spacing(3, 0, 4),
      },
    },
    main: {
      position: "relative"
    },
    footerContainer: {
      flexShrink: 0, 
    },
    footer: {
      background: theme.palette.color.black,
    },
    [theme.breakpoints.down("xl")]: {
      root: {
        "& article": {
          maxWidth: 1280,
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      root: {
        "& article": {
          maxWidth: 960,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      root: {
        "& article": {
          maxWidth: 600,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      root: {
        "& article": {
          maxWidth: "100%",
          padding: theme.spacing(0, 1),
        },
      },
    },
  };
});
