import { createActionFactory } from "helpers/actionFactory";

const factory = createActionFactory("ITEMS");

export const getAbout = factory.create("GET_ABOUT");
export const getAboutAsync = factory.createAsync("GET_ABOUT_ASYNC");

export const getHeaderInfo = factory.create("GET_HEADER_INFO");
export const getHeaderInfoAsync = factory.createAsync("GET_HEADER_INFO_ASYNC");

export const StaticInfoActions = {
    getAbout,
    getAboutAsync,
    getHeaderInfo,
    getHeaderInfoAsync,
};
