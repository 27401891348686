import axios from "axios";
import { axiosMain } from "helpers/axios"
import { IAbout } from "views/about/types";

export default class StaticInfoApi {
    static getAbout = () => {

        const aboutReq = axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/web/about`);
        const branchesReq = axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/branch`);

        return axios.all([aboutReq, branchesReq]).then(axios.spread((...responses) => {
            const resArr = responses?.map(r => r.data?.data)
            return {
                content: resArr[0]?.content,
                branches: resArr[1],
            } as IAbout
        })
        )
    }

    static getHeaderInfo = () => {
        return axiosMain.get(`web/header-info`).then((payload) => payload?.data)
    }

    static getBranches = () => {
        return axiosMain.get(`/branch`).then((payload) => payload?.data)
    }
}