import React, { FC } from "react";
import { useStyles } from "./footer.style";
import { useTranslator } from "localization";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { Grid, isWidthUp, withWidth } from "@material-ui/core";
import { Menu } from "../header/components";
import bottomPatternWhite from "assets/bottomPatternWhite.png";
import PhoneIcon from "@material-ui/icons/Phone";
import { IAppState } from "store/state/reducers";
import { connect } from "react-redux";
import { IHeaderInfo } from "views/about/types";
import { WallLine } from "./components";

export interface IFooter {
  width: any;
  info: IHeaderInfo;
}

const Footer: FC<IFooter> = ({ width, info }) => {
  const lang = useTranslator("footer");
  const classes = useStyles();

  return (
    <article>
      <Grid container className={classes.root}>
        <Grid item xs={8} md={6} className={classes.icons}>
          <div>
            <div>
              <PhoneIcon />
              <a href="tel:+994550000000">{info.phone1}</a>
            </div>
            {info.phone2 && (
              <div>
                <PhoneAndroidIcon />
                <a href="tel:+994550000000">{info.phone2}</a>
              </div>
            )}
          </div>
          <div>
            <div>
              <AlternateEmailIcon />
              <a href={`mailto: ${info.email}`}>{info.email}</a>
            </div>
            <a target="_blank" href={info.facebook || "#"}>
              <FacebookIcon />
            </a>
            <a target="_blank" href={info.instagram || "#"}>
              <InstagramIcon />
            </a>
          </div>
        </Grid>
        <Grid item xs={4} md={6} className={classes.menu}>
          <Menu variant={isWidthUp("sm", width) ? "web" : "vertical"} navigator={false} />
        </Grid>
        <Grid item xs={12} className={classes.author}>
          <div>
            © 2021 - {new Date().getFullYear()} by{" "}
            <a target="_blank" href="https://www.facebook.com/upline.ui">
              Upline
            </a>{" "}
            | {lang.allRightServed}
          </div>
        </Grid>
      </Grid>
    </article>
  );
};

const mapStateToProps = (state: IAppState) => ({
  info: state.staticInfo.headerInfo,
  loading: state.items.loading,
});
export default withWidth()(connect(mapStateToProps, {})(Footer));
