import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { Main, About, Item, Items } from "views";

export interface ILayoutRoutes {}

export const LayoutRoutes: FC<ILayoutRoutes> = () => {
  return (
    <Switch>
      <Route path={["/wallpaper/:id/:color?", "/moydader/:id", "/ceiling/:id"]} component={Item} />
      <Route path={["/wallpaper", "/moydader", "/ceiling"]} component={Items} />
      <Route path="/about" component={About} />
      <Route path="/" component={Main} />
    </Switch>
  );
};
