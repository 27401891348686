import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    photo: {
      position: "relative",
      overflow: "hidden",
      "& > div": {
        //boxShadow: "-1px 0 10px rgb(0 0 0 / 30%)",
        transition: "all .2s ease-in-out 0s",
        padding: theme.spacing(0.2),
        "& img": {
          width: "100%",
          height: 400,
          backgroundSize: "cover",
        },
      },
    },
    thumbNails: {
      scrollBehavior: "smooth",
      overflowX: "hidden",
      margin: "0 auto",
      "& .MuiButtonBase-root ": {
        height: 90,
        width: 190,
        overflow: "hidden",
      },
      "& img": {
        objectFit: "contain",
      },
    },
    activeThumb: {
      border: `1px solid ${theme.palette.color.black}`,
    },
    sliderCover: {
      background: "#fff",
      boxShadow: "0 0 10px rgba(0,0,0,0.3)",
      margin: "26px auto 16px",
      maxWidth: 550,
      minHeight: 300,
      padding: 4,
      position: "relative",
      width: "80%",
      "&:before, &:after": {
        content: "''",
        height: "98%",
        position: "absolute",
        width: "100%",
        zIndex: -1,
      },
      "&:before": {
        background: "#fff",
        boxShadow: "0 0 8px rgba(0,0,0,0.2)",
        left: -5,
        top: 4,
        transform: "rotate(-2.5deg)",
      },
      "&:after": {
        background: "#FAFAFA",
        boxShadow: "0 0 3px rgba(0,0,0,0.2)",
        right: -3,
        top: 1,
        transform: "rotate(1.4deg)",
      },
    },
    paletContainer: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: 16,
      right: 12,
      background: "rgba(255,255,255,0.6)",
      boxShadow: "0 0 8px rgba(0,0,0,0.2)",
      transition: "all .2s ease 0s",
      borderRadius: 4,
      padding: 3,
      "&:hover": {
        background: "rgba(255,255,255,0.8)",
      },
      "& p": {
        margin: 0,
        whiteSpace: "nowrap",
        marginRight: 8,
        fontWeight: "bold",
        fontSize: 13,
      },
      "& div": {
        height: 20,
        width: 20,
        borderRadius: "20%",
        marginRight: theme.spacing(0.5),
      },
    },
    arrow: {
      position: "absolute",
      top: "50%",
      tansform: "translateY(-50%)",
    },
    leftArrow: {
      left: 8,
    },
    rightArrow: {
      right: 8,
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
        sliderCover: {
            width: "100%"
        }
    },
  };
});
