import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    img: {
      height: 80,
      objectFit: "contain",
      position: "absolute",
      zIndex: 0
    },
    tl: { top: 0, left: 0, transform: "rotate(180deg) scaleX(-1)" },
    tr: { top: 0, right: 0, transform: "rotate(180deg)" },
    bl: { bottom: 0, left: 0 },
    br: { bottom: 0, right: 0, transform: "rotate(180deg) scaleY(-1)" },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      img: {
        height: 50,
      },
    },
  };
});
