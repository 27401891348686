import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
    return {
        loader: {
            position: "absolute",
            height: "100vh",
            width: "100vw",
            background: "rgba(255,255,255,0.8)",
            zIndex: 2,
            left: 0,
            top: 0,
            "& img": {
                position: "relative",
                height: 200,
                width: 290,
                left: "50%",
                top: "40%",
                transform: "translate(-50%,-50%)",
            },
        },
        progress: {
            height: 2,
            "&.MuiLinearProgress-colorPrimary": {
                backgroundColor: "rgba(0,0,0,0)"
            },
            "& .MuiLinearProgress-bar": {
                backgroundColor: "rgba(0,0,0,0.4)"
            }
        },
        [theme.breakpoints.down("xl")]: {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.down("xs")]: {
            loader: {
                "& img": {
                    height: 150,
                    width: 200,
                    left: "50%",
                    top: "40%",
                    transform: "translate(-50%,-50%)",
                },
            },
        },
    };
});