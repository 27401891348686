import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(3, 0),
      "& > img": {
        height: 20,
        marginTop: theme.spacing(2),
      },
    },
    wallpaperList: {
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.down("xl")]: {
      root: {
        padding: theme.spacing(6, 0),
        "& > img": {
          marginTop: theme.spacing(5),
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      root: {
        padding: theme.spacing(3, 0),
        "& > img": {
          marginTop: theme.spacing(2),
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      root: {
        padding: theme.spacing(5, 0),
        "& > img": {
          marginTop: theme.spacing(3),
        },
      },
      sectionHeader: {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      root: {
        padding: theme.spacing(2.5, 0),
      },
    },
  };
});
