import { handleActions } from "redux-actions";
import { IAbout, IBranches, IHeaderInfo } from "views/about/types";
import { ICard, IFilterColor, IItems } from "views/items/types";
import { getAboutAsync, getHeaderInfoAsync } from "../actions/static-info.actions";

export interface StaticActionReducer {
    about: IAbout;
    headerInfo: IHeaderInfo;
    loading: boolean
}

const initialState: StaticActionReducer = {
    about: {} as IAbout,
    headerInfo: {} as IHeaderInfo,
    loading: false
};

export default handleActions(
    {
        [getAboutAsync.started]: (state) => ({ ...state, loading: true }),
        [getAboutAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getAboutAsync.success]: (state, action: any) =>
        ({
            ...state,
            about: action.payload,
            loading: false,
        }),
        [getHeaderInfoAsync.started]: (state) => ({ ...state, loading: true }),
        [getHeaderInfoAsync.failed]: (state) => ({ ...state, loading: false, }),
        [getHeaderInfoAsync.success]: (state, action: any) =>
        ({
            ...state,
            headerInfo: action.payload.data,
            loading: false,
        })

    },
    initialState
);
