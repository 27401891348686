import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
    return {
        [theme.breakpoints.down("xl")]: {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.down("xs")]: {},
    };
});