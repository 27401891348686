import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import oboy from "assets/aboy.jpg";
import oboy1 from "assets/aboy1.jpg";
import moydadir from "assets/moydadir.jpg";
import moydadir2 from "assets/moydadir2.jpg";

export const useStyles = makeStyles((theme) => {
  return {
    item: {
      backgroundSize: "cover",
      height: "100vh",
    },
    itemContent: {
      backgroundSize: "cover",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "& > div": {
        width: "100%",
        position: "relative",
        right: "-50%",
        backgroundColor: "rgba(256, 256, 256, .8)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        border: `6px double ${grey[900]}`,
        height: "100%",
        "& div": {
          display: "flex",
          justifyContent: "center",
          padding: theme.spacing(0, 10),
          fontWeight: "bold",
          height: "100%",
        }
      },
      "& h2": {
        textAlign: "center",
        margin: theme.spacing(2, 0, 5, 0),
      },
    },
    [theme.breakpoints.down("xl")]: {
      itemContent: {
        padding: theme.spacing(8, 8),
        "& div": {
          height: "50%",
        },
      },
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      itemContent: {
        backgroundPosition: "100% 0",
        padding: theme.spacing(0, 8),
        overflow: "hidden",
        "& div": {
          right: 0,
          height: "40%",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      itemContent: {
        padding: theme.spacing(0, 2),
        "& > div": {
          width: "100%",
          right: 0,
          height: "70%",
          "& div": {
            padding: theme.spacing(0, 1),
          }
        },
      },
    },
    [theme.breakpoints.down("xs")]: {},
  };
});
