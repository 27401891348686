import React, { FC } from "react";
import { useTranslator } from "localization";
import { useStyles } from "./wallline.style";

export interface IWallLine {}

export const WallLine: FC<IWallLine> = ({}) => {
  const lang = useTranslator();
  const classes = useStyles();
  const delayList = [100, 120, 130, 140, 150, 160, 170];

  const wallsL = [
    "l1",
    "l2",
    "l3",
    "l4",
    "l5",
    "l6",
    "l1",
    "l3",
    "l4",
    "l2",
    "l5",
    "l6",
    "l5",
    "l6",
    "l1",
    "l4",
    "l2",
    "l5",
    "l6",
    "l1",
    "l3",
    "l4",
    "l2",
    "l5",
    "l6",
    "l5",
    "l8",
    "l1",
    "l3",
    "l4",
    "l2",
    "l5",
    "l6",
    "l5",
    "l6",
    "l1",
    "l4",
    "l5",
    "l6",
    "l1",
    "l4",
    "l2",
    "l5",
    "l6",
    "l1",
    "l3",
    "l4",
    "l2",
    "l5",
    "l5",
    "l6",
    "l1",
    "l3",
    "l4",
    "l2",
    "l5",
    "l6",
    "l5",
    "l8",
    "l1",
    "l3",
    "l5",
    "l9",
    "l3",
  ];

  const wallsT = [
    "t1",
    "t2",
    "t3",
    "t4",
    "t5",
    "t7",
    "t8",
    "t9",
    "t10",
    "t2",
    "t3",
    "t4",
    "t7",
    "t2",
    "t3",
    "t4",
    "t5",
    "t10",
    "t2",
    "t3",
    "t4",
    "t1",
    "t2",
    "t3",
    "t4",
    "t5",
    "t2",
    "t3",
    "t4",
    "t7",
    "t2",
    "t3",
    "t4",
    "t5",
    "t10",
    "t10",
    "t2",
    "t3",
    "t4",
    "t7",
    "t2",
    "t3",
    "t4",
    "t5",
    "t10",
    "t2",
    "t4",
    "t5",
    "t2",
    "t3",
    "t4",
    "t7",
    "t2",
    "t3",
    "t4",
    "t5",
    "t10",
    "t10",
    "t2",
    "t2",
    "t3",
    "t4",
    "t7",
    "t10",
  ];

  return (
    <div className={classes.root}>
      <div className={classes.topLine}>
        {wallsT.map((img, i) => (
          <img
            src={require(`../../../../../assets/walline/${img}.png`).default}
            key={i}
            className={classes[img]}
            draggable={false}
            data-aos="fade-up"
            data-aos-offset={40}
            data-aos-delay={300 + delayList[Math.floor(Math.random() * delayList.length)]}
            data-aos-anchor-placement="top-bottom"
            data-aos-duration={600}
          />
        ))}
      </div>
      <div className={classes.bottomLine}>
        {wallsL.map((img, i) => (
          <img
            src={require(`../../../../../assets/walline/${img}.png`).default}
            key={i}
            className={classes[img]}
            draggable={false}
            data-aos="fade-up"
            data-aos-offset={40}
            data-aos-delay={delayList[Math.floor(Math.random() * delayList.length)]}
            data-aos-anchor-placement="top-bottom"
            data-aos-duration={600}
          />
        ))}
      </div>
    </div>
  );
};
