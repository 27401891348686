import { Badge, Chip, Grid, isWidthUp, withWidth } from "@material-ui/core";
import sidePattern from "assets/sidePattern.png";
import { Loader } from "components/shared";
import { useTranslator } from "localization";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ItemActions } from "store/actions/item.actions";
import { IAppState } from "store/state/reducers";
import { ICard, IItemsField } from "views/items/types";
import { ListItem, Slider } from "./components";
import { useStyles } from "./item.style";

export interface IItem {
  item: ICard;
  loading: boolean;
  getItem({ link, id }: any): void;
  currentColor?: string;
  width: any;
}

const Item: FC<IItem> = ({ item, loading, getItem, width }) => {
  const lang = useTranslator("main");
  const classes = useStyles();
  const location = useLocation();
  const params: any = useParams();
  const link = location.pathname?.split("/")[1];

  const [currItem, setCurrItem] = useState<IItemsField>({} as IItemsField);

  useEffect(() => {
    getItem({ link, id: params.id });
  }, []);

  useEffect(() => {
    if (item && item?.items && link === "wallpaper") {
      setCurrItem(item?.items?.filter((c) => c.color === `#${params.color}`)[0] || item?.items[0]);
    }
  }, [item]);

  const renderMoydadirItems = () => {
    return (
      <div className={classes.accessuars}>
        {item?.items?.map((i) => (
          <Chip key={i.code} label={`${i.name} ⦁ ${i.code}`} />
        ))}
      </div>
    );
  };

  return (
    <Loader loading={loading}>
      <article className={classes.article}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Slider
              item={item}
              isSlider={link === "wallpaper"}
              currItem={currItem}
              onThumbClick={setCurrItem}
              itemToShow={isWidthUp("sm", width) ? 3 : 2}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.info}>
            <div>
              <div className={classes.code}>
                <img src={sidePattern} alt="" />
                <img src={sidePattern} alt="" />
                <h1>{link === "wallpaper" ? currItem?.code : item.code}</h1>
              </div>
              <ListItem title={lang.category} text={item.category?.name} bgColor={currItem?.color} />
              <ListItem
                title={lang.sizes}
                hidden={link !== "moydader"}
                text={item?.size?.toString()}
                bgColor={currItem?.color}
              />
              <ListItem
                title={lang.accesuars}
                hidden={link !== "moydader"}
                text={renderMoydadirItems()}
                bgColor={currItem?.color}
              />
              <ListItem title={lang.aditionalInfo} textSize="small" text={item.about} bgColor={currItem?.color} />
            </div>
          </Grid>
        </Grid>
      </article>
    </Loader>
  );
};

const mapStateToProps = (state: IAppState) => ({
  item: state.items.item,
  loading: state.items.loading,
});
export default connect(mapStateToProps, ItemActions)(withWidth()(Item));
