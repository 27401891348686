import { Grid } from "@material-ui/core";
import bottomPattern from "assets/bottomPattern.png";
import { CardList, SectionHeader } from "components/shared";
import { useTranslator } from "localization";
import React, { FC } from "react";
import { IItems } from "views/items/types";
import { useStyles } from "./card-list.style";

export interface ICardListMain {
  header: string;
  list: IItems;
  type: "wallpaper" | "moydader" | "ceiling";
}

export const ICardListMain: FC<ICardListMain> = ({ header, list, type }) => {
  const lang = useTranslator();
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.sectionHeader}>
        <SectionHeader text={header} />
      </div>
      <CardList list={list} paginate={false} centered urlStart={type} />
      <img src={bottomPattern} alt="sectionPattern" />
    </Grid>
  );
};
