import React, { FC, useEffect, useState } from "react";
import { useTranslator } from "localization";
import { useStyles } from "./items.style";
import { FilterBar } from "./components";
import { CardList, Loader } from "components/shared";
import { IAppState } from "store/state/reducers";
import { IItemReducer } from "store/reducers/item.reducer";
import { ItemActions } from "store/actions/item.actions";
import { connect } from "react-redux";
import { IByColorReq, IFilterColor, IGetItems } from "./types";
import { useLocation } from "react-router";

export interface IItemsPage {
  items: IItemReducer;
  getItems(data: IGetItems): void;
  getColors(): void;
  getCategs(link: string): void;
  colors: IFilterColor[];
}

const Items: FC<IItemsPage> = ({ items, getItems, colors, getColors, getCategs }) => {
  const lang = useTranslator();
  const classes = useStyles();
  const location = useLocation();
  const link = location.pathname?.substr(1, location.pathname?.length);
  const [page, setPage] = useState(1);
  const [colorArr, setColorArr] = useState<string[]>([]);
  const [categ, setCateg] = useState<number | undefined>(undefined);
  const size = 12;

  useEffect(() => {
    setColorArr([]);
    setPage(1);
    getCategs(link);
    if (link === "wallpaper") {
      getColors();
    }
  }, [link]);

  useEffect(() => {
    if (page && size) {
      getItems({ link, filter: { page, size, color: colorArr.length > 0 ? colorArr : undefined, categ } });
    }
  }, [page, colorArr.length, link, categ]);

  return (
    <Loader loading={items.loading}>
      <article>
        <FilterBar
          oncategChange={setCateg}
          categs={items.categs}
          onColorClick={setColorArr}
          colors={link === "wallpaper" ? colors : undefined}
        />
        <CardList list={items.items} categs={items.categs} onPaginate={setPage} size={size} paginate={items.items?.totalCount > size} />
      </article>
    </Loader>
  );
};

const mapStateToProps = (state: IAppState) => ({ items: state.items, colors: state.items.colors });
export default connect(mapStateToProps, ItemActions)(Items);
