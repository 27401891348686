import React, { FC, useEffect } from "react";
import { useStyles } from "./about.style";
import { useTranslator } from "localization";
import { CornerPattern, JoditContainer, Loader, Logo } from "components/shared";
import { IAppState } from "store/state/reducers";
import { connect } from "react-redux";
import { StaticInfoActions } from "store/actions/static-info.actions";
import { IAbout, IBranches } from "./types";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export interface IAboutPage {
  about: IAbout;
  getAbout(): void;
  siteName: string;
  loading: boolean;
  logo: string;
}

const About: FC<IAboutPage> = ({ about, getAbout, siteName, loading, logo }) => {
  const lang = useTranslator();
  const classes = useStyles();

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <Loader loading={loading}>
      <article className={classes.root}>
        <div>
          <CornerPattern className={classes.pattern} image="leaf" />
          <div className={classes.title}>
            <Logo name={siteName} img={logo} />
          </div>
          <JoditContainer content={about.content} className={classes.content} />
          <List
            className={classes.branchList}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {lang.ourBranches}
              </ListSubheader>
            }
          >
            {about.branches?.map((b, i) => (
              <ListItem key={i}>
                <ListItemText
                  id={b.name}
                  primary={b.name}
                  secondary={
                    <>
                      <LocationOnIcon /> {b.address}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </article>
    </Loader>
  );
};

const mapStateToProps = (state: IAppState) => ({
  about: state.staticInfo.about,
  siteName: state.staticInfo.headerInfo.name,
  loading: state.staticInfo.loading,
  logo: state.staticInfo.headerInfo.logo,
});
export default connect(mapStateToProps, StaticInfoActions)(About);
