import { useTranslator } from "localization";
import { links } from "routes/links";

export const useMenu = () => {
  const lang = useTranslator("menu");

  const menu = [
    {
      label: lang.mainPage,
      link: links.main,
    },
    {
      label: lang.wallpapers,
      link: links.wallpapers,
    },
    {
      label: lang.moyDadders,
      link: links.moydadirs,
    },
    {
      label: lang.ceilings,
      link: links.ceilings,
    },
    {
      label: lang.aboutUs,
      link: links.about,
    },
  ];

  return menu;
};
