import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2, 0),
    },
    icons: {
      display: "flex",
      "& > div": {
        display: "flex",
        "& > div": {
          display: "flex",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
            marginRight: theme.spacing(0.5),
            color: theme.palette.common.white,
          },
        },
      },
      "& a": {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
        display: "inline",
      },
    },
    author: {
      padding: theme.spacing(4, 0, 0, 0),
      position: "relative",
      color: grey[400],
      fontSize: 15,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      "& a": {
        color: grey[100],
      },
    },
    menu: {
      display: "flex",
      justifyContent: "flex-end",
      "& ul": {
        "& li": {
          "& a": {
            color: theme.palette.color.white,
            padding: theme.spacing(0, 1),
            fontWeight: "normal",
            fontSize: 16,
          },
        },
      },
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      icons: {
        display: "block",
        "& > div": {
          margin: theme.spacing(1, 0),
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      icons: {
        display: "block",
        "& > div": {
          display: "block",
          "& > div": {
            display: "block",
            margin: theme.spacing(1, 0),
          },
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      root: {
        padding: theme.spacing(2, 1),
      },
      menu: {
        justifyContent: "center",
        "& ul": {
          "& li": {
            "& a": {
              padding: theme.spacing(0.5, 0),
              fontSize: 13
            },
          },
        },
      },
      icons: {
        "& > div": {
          "& > div": {
            whiteSpace: "nowrap",
            "& svg": {
              fontSize: 16,
            },
          },
        },
        "& a": {
          marginRight: theme.spacing(1),
          fontSize: 13,
        },
      },
    },
  };
});
