import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Layout } from "layout";

const Routes = ({ history }: any) => {
  return (
    <Router>
      <Suspense fallback={<>loading...</>}>
        <Switch>
          <Route path="/" component={Layout} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
