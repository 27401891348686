import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      boxShadow: "-1px 0 6px rgb(0 0 0 / 20%)",
    },
    marginBottom: {
      //marginBottom: theme.spacing(2),
    },
    favMenuContainer: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xl")]: {
      logo: {
        "& a": {
          fontSize: 40,
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      logo: {
        "& a": {
          fontSize: 30,
        },
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      logo: {
        "& a": {
          fontSize: 27,
        },
      },
    },
  };
});
