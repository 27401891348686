import React, { useState, MouseEvent, FC } from "react";
import { Badge, Button, Menu, MenuItem, IconButton, SvgIconTypeMap, CircularProgress } from "@material-ui/core";
import { useStyles } from "./context-menu.style";
import withWidth, { isWidthUp, WithWidthProps } from "@material-ui/core/withWidth";
import { useTranslator } from "localization";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import clsx from "clsx";
import noimg from "assets/noimg.png";
import CloseIcon from "@material-ui/icons/Close";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

export interface IBasketProduct {
  id: number;
  code: string;
  photo: string;
  color: string;
}

export interface IContextMenu extends WithWidthProps {
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  onOpen?(): void;
  onOpenBasketClick?(): void;
  list: IBasketProduct[];
  className?: string;
  style?: any;
  onItemClick?(id: number): void;
  onRemove?(id: number): void;
  buttonText?: string;
  width: any;
  count: number;
  loading?: boolean;
}

const FavoriteMenu: FC<IContextMenu> = ({
  icon,
  list,
  className,
  style,
  buttonText,
  onOpen = () => {},
  onOpenBasketClick = () => {},
  onItemClick = () => {},
  onRemove = () => {},
  width,
  count,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const lang = useTranslator("header");

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (isWidthUp("md", width)) {
      setAnchorEl(event.currentTarget);
      onOpen();
    } else {
      onOpenBasketClick();
    }
  };

  const handleOpenBasket = () => {
    setAnchorEl(null);
    onOpenBasketClick();
  };

  const renderList = (list: IBasketProduct[]) => {
    return list?.map((product: IBasketProduct) => (
      <MenuItem key={product.id} button disableRipple>
        <div
          className={classes.itemContent}
          onClick={() => {
            onItemClick(product.id);
            setAnchorEl(null);
          }}
        >
          <img src={product.photo} alt={product.code} onError={(e: any) => (e.target.src = noimg)} />
          <div>
            <span>{product.code}</span>
          </div>
        </div>
        <div>
          <IconButton onClick={() => onRemove(product.id)}>
            <CloseIcon />
          </IconButton>
        </div>
      </MenuItem>
    ));
  };

  const renderOpenButton = () => {
    return (
      <div className={classes.listFooter}>
        <Button onClick={handleOpenBasket} fullWidth color="primary">
          {buttonText ? buttonText : lang.gotToBasket}
        </Button>
      </div>
    );
  };

  return (
    <div className={clsx("Favorite-menu", className)} style={style}>
      <Button aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick} className={classes.openButton}>
        <Badge badgeContent={count} color="secondary" invisible={count === 0} classes={{ root: classes.badge }}>
          <FavoriteBorderIcon />
        </Badge>
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.menu }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {loading ? (
          <div className={classes.loadingIcon}>
            <CircularProgress />
          </div>
        ) : (
          renderList(list)
        )}
        {count === 0 && (
          <div className={classes.noItem}>
            <div className={classes.noItemIcon}></div>
            <div>{lang.basketIsEmpty}</div>
          </div>
        )}
        {count > 0 && renderOpenButton()}
      </Menu>
    </div>
  );
};

export default withWidth()(FavoriteMenu);
