import anyLang from "./lang/az.json";
type Page = keyof typeof anyLang;

export const useTranslator = (
  page?: Page,
  other?: Page[] // other pages you want include to lang object
) => {
  const langState = window.location.pathname.split("/")[1] || "az";
  const langObj = ["az", "en", "ru"].includes(langState)
    ? (require(`./lang/${langState}.json`) as typeof anyLang)
    : anyLang;

  const _getPageLang = (page?: Page) => {
    if (page && page.toLowerCase() === "main") {
      return langObj["main"];
    } else if (page && page.toLowerCase() !== "main" && Object.keys(langObj).includes(page)) {
      return { ...langObj["main"], ...langObj[page as keyof typeof langObj] };
    } else {
      let allLangs = {};
      Object.keys(langObj).map((key: string) => (allLangs = { ...allLangs, ...langObj[key as keyof typeof langObj] }));
      return allLangs;
    }
  };

  const _getCombinedPages = (page?: Page, other?: Page[] | undefined) => {
    let pageLang = _getPageLang(page);

    if (other && other.length > 0) {
      other.map((pg: Page) => {
        pageLang = { ...pageLang, ...langObj[pg as keyof typeof langObj] };
        return pageLang;
      });
    }
    return pageLang as any;
  };

  return _getCombinedPages(page, other);
};

export const replace = (text: string, words: any[] | any) => {
  const _replace = (text: string, words: string[] | string) => {
    if (typeof words === "string") {
      return text.replaceAll(`$0`, words);
    } else {
      for (let ind in words) {
        text = text.replaceAll(`$${ind}`, words[ind]);
      }
      return text;
    }
  };

  return _replace(text, words);
};
