import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& h1, & h2, & h3 & h4, & h5, & h6": {
        margin: theme.spacing(0.5, 0, 1, 0),
      },
      "& ol": {
        paddingLeft: theme.spacing(2),
        fontSize: 16,
      },
      "& ol li::marker": {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
      "& ol li, & ul li": {
        padding: theme.spacing(1),
      },
      "& ul": {
        listStyleType: `none`,
        //listStyleImage: `url(${checkIcon})`,
        margin: theme.spacing(0, 0, 0, 0),
        padding: 0,
        fontSize: 16,
      },
      "& ul li:before": {
        content: "''",
        display: "inline-block",
        height: 20,
        width: 20,
        // backgroundImage: `url(${checkIcon})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        marginRight: theme.spacing(1),
        top: 5,
        position: "relative",
      },
      "& p": {
        lineHeight: "28px !important",
        fontSize: 16,
        background: "transparent !important",
        margin: theme.spacing(0.5, 0, 1, 0),
        width: "100% !important",
      },
      "& h1": {
        fontSize: 28,
      },
      "& iframe": {
        width: "80%",
        display: "block",
        borderRadius: 8,
      },
      "& img": {
        width: "80% !important",
        minHeight: "300px !important",
        objectFit: "cover",
        borderRadius: 8,
      },
      "& img[alt~='big']": {
        minWidth: "100% !important",
      },
      "& img[alt~='small']": {
        minWidth: "30% !important",
      },
      "& img[alt~='medium']": {
        minWidth: "50% !important",
      },
      "& table": {
        borderCollapse: "collapse",
        background: `${theme.palette.color.white}  !important`,
        boxShadow: "0 1px 3px rgb(43 45 55 / 10%)",
        "& td": {
          padding: theme.spacing(2),
        },
        "& tr": {
          borderBottom: `1px solid ${grey[300]}`,
        },
        "& tr:last-child": {
          borderBottom: `0px`,
        },
      },
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      root: {
        "& img": {
          width: "100% !important",
          height: "200px !important",
          objectFit: "cover",
          borderRadius: 8,
        },
        "& iframe": {
          width: "100%",
          height: "200px !important",
        },
        "& h1": {
          fontSize: 24,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {},
  };
});
