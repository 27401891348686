import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      boxShadow: "0 2px 16px 0 rgb(0 0 0 / 15%)",
      WebkitBoxShadow: "0 2px 16px 0 rgb(0 0 0 / 15%)",
      position: "relative",
      height: 390,
      overflow: "hideen",
      cursor: "pointer",
      "& $favButton": {
        position: "absolute !important",
      }
    },
    favButton: {
      top: 4,
      right: 8,
      backgroundColor: "rgba(256, 256, 256, .5) !important",
      padding: theme.spacing(0.5),
      boxShadow: "0 2px 16px 0 rgb(0 0 0 / 15%)",
      WebkitBoxShadow: "0 2px 16px 0 rgb(0 0 0 / 15%)",
      border: `2px solid ${grey[400]}`,
      "&:hover": {
        backgroundColor: "rgba(256, 256, 256, .9) !important",
      },
      "& svg": {
        fontSize: 22,
        color: grey[700],
      },
    },
    img: {
      width: "100%",
      height: "100%",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    info: {
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(256, 256, 256, .8)",
      padding: theme.spacing(2, 1),
      width: "100%",
    },
    colorContainer: {
      display: "flex",
      position: "absolute",
      maxWidth: 98,
      flexWrap: "wrap",
      top: 8,
      left: 8,
      "& > div": {
        height: 25,
        width: 25,
        borderRadius: 4,
        border: `1px solid #fff`,
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(.5),

      },
    },
    [theme.breakpoints.down("xl")]: {
      root: {
        height: 400,
      },
    },
    [theme.breakpoints.down("lg")]: {
      root: {
        height: 390,
      },
    },
    [theme.breakpoints.down("md")]: {
      root: {
        height: 280,
      },
    },
    [theme.breakpoints.down("sm")]: {
      root: {
        height: 280,
      },
    },
    [theme.breakpoints.down("xs")]: {
      root: {
        height: 200,
      },
      info: {
        padding: theme.spacing(0.5),
        "& h3": {
          fontSize: 16,
        },
      },
    },
  };
});
