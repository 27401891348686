import React, { FC } from "react";
import { useStyles } from "./logo.style";
import { useTranslator } from "localization";
import clsx from "clsx";

export interface ILogo {
  className?: string;
  name: string;
  img?: string;
}

export const Logo: FC<ILogo> = ({ className, name, img }) => {
  const lang = useTranslator();
  const classes = useStyles();

  return img ? (
    <img src={img} className={classes.logoImg} alt="favorite gallery" />
  ) : (
    <h1 className={clsx(classes.logo, className)}>{name}</h1>
  );
};
