import axiosBase from "axios";
import { toast } from "react-toastify";

export const axiosMain = axiosBase.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1/`,
});

axiosMain.interceptors.request.use((config) => {
  config.headers["accept-language"] = window.location.pathname.split("/")[1] || "az";
  return config;
});

axiosMain.interceptors.response.use(
  (response) => response,
  (error) => {
    const messages = error.response?.data?.message || error.response?.data?.data || error.response?.message;
    if (messages && Array.isArray(messages)) {
      for (let err of messages) {
        toast.error(err);
      }
    }
    else {
      toast.error(messages);
    }
  }
);

