import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AOS from "aos";
import "aos/dist/aos.css";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes/routes";
import { getStore } from "store/state";
import theme from "theme/theme";
import "./App.css";

const history = createBrowserHistory();
const store = getStore(history);

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 700,
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
        <ToastContainer autoClose={3000} position="top-center" />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
