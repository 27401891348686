import { QueueSharp } from "@material-ui/icons"
import axios from "axios"
import { axiosMain } from "helpers/axios"
import { IByColorReq, IGetFilter, IGetItems } from "views/items/types"
import { IMainItems } from "views/main/types"
import qs from "qs"


export default class ItemApi {
    static getItems = ({ link, filter }: IGetItems) => {
        return axiosMain.get(`${link}/${filter.page}/${filter.size}`, {
            params: { color: filter?.color, categoryId: filter?.categ }, paramsSerializer: p => {
                return qs.stringify(p)
            }
        }).then((payload) => payload?.data)
    }

    static getItem = ({ link, id }: any) => {
        return axiosMain.get(`${link}/${id}`).then((payload) => payload?.data)
    }

    static getColor = () => {
        return axiosMain.get(`wallpaper/colors`).then((payload) => payload?.data)
    }

    static getMainItems = (count: number) => {
        const wallReq = axiosMain.get(`wallpaper/1/${count}`);
        const wallInfoReq = axiosMain.get(`info`, { params: { type: "wallpapers" } });
        const ceilingReq = axiosMain.get(`ceiling/1/${count}`);
        const ceilingInfoReq = axiosMain.get(`info`, { params: { type: "ceilings" } });
        const moydadirReq = axiosMain.get(`moydader/1/${count}`);
        const moydadirInfoReq = axiosMain.get(`info`, { params: { type: "moydaders" } });

        return axios.all([wallReq, wallInfoReq, ceilingReq, ceilingInfoReq, moydadirReq, moydadirInfoReq]).then(axios.spread((...responses) => {
            const resArr = responses?.map(r => r.data?.data)
            return {
                wallpapers: { cards: resArr[0], info: resArr[1] },
                ceilings: { cards: resArr[2], info: resArr[3] },
                moydaders: { cards: resArr[4], info: resArr[5] },
            } as IMainItems

        }))
    }

    static getCategs = (link: string) => {
        return axiosMain.get(`${link}category`).then((payload) => payload?.data)
    }
}