import React, { FC, useEffect } from "react";
import { useTranslator } from "localization";
import { useStyles } from "./loader.style";
import loaderGif from "assets/aaa.gif";
import { LinearProgress } from "@material-ui/core";

export interface ILoader {
  loading: boolean;
}

export const Loader: FC<ILoader> = ({ loading = false, children }) => {
  const lang = useTranslator();
  const classes = useStyles();

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <LinearProgress className={classes.progress} />
          <img src={loaderGif} alt="loader" draggable={false} />
        </div>
      )}
      {children}
    </>
  );
};
