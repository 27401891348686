import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => {
  return {
    menu: {
      border: "1px solid #d3d4d5",
      width: 400,
      flexWrap: "wrap",
      maxHeight: 400,
      "& .MuiMenu-list": {
        padding: 0,
      },
      "& .MuiListItem-root": {
        display: "flex",
        whiteSpace: "break-spaces",
      },
    },
    openButton: {
      "& .MuiSvgIcon-root": {
        fontSize: 27,
        color: grey[900],
      },
    },
    badge: {
      padding: `${theme.spacing(0)}`,
      "& .MuiBadge-badge": {
        right: 5,
        top: 5,
      },
    },
    itemContent: {
      display: "flex",
      alignItems: "center",
      "& img": {
        height: 55,
        width: 70,
        display: "block",
        objectFit: "cover",
        borderRadius: 10,
      },
      "& > div": {
        width: 240,
        marginLeft: theme.spacing(2),
        whiteSpace: "break-spaces",
        "& span:nth-child(1)": {
          fontWeight: "bold",
        },
        "& span:nth-child(2)": {
          display: "flex",
          fontSize: 14,
          color: grey[600],
          "& .MuiSvgIcon-root": {
            marginLeft: -5,
            fontSize: 16,
          },
        },
        "& div": {
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          fontWeight: "bold",
          display: "inline-block",
          padding: theme.spacing(0, 0.5),
          borderRadius: 4,
          fontSize: 15,
        },
      },
    },

    listFooter: {
      position: "sticky",
      background: theme.palette.common.white,
      bottom: 0,
      padding: theme.spacing(2, 2, 1, 2),
      display: "flex",
      justifyContent: "space-between",
      "& .MuiButton-root": {
        "& span": {
          fontSize: "1rem",
          marginRight: theme.spacing(1),
        },
      },
    },
    noItem: {
      padding: theme.spacing(5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    noItemIcon: {
      width: "100%",
      textAlign: "center",
      "& span": {
        fontSize: "3rem",
        color: grey[700],
      },
    },
    loadingIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2, 0, 0, 0),
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      badge: {
        "& .MuiSvgIcon-root": {
          fontSize: 28,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      openButtonIcon: {
        color: theme.palette.common.white,
      },
    },
    [theme.breakpoints.down("xs")]: {
      openButton: {
        "& .MuiSvgIcon-root": {
          fontSize: 24,
          color: theme.palette.primary.main,
        },
      },
    },
  };
});
