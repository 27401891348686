import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";



export const useStyles = makeStyles((theme) => {
  return {
    cover: {
      height: "calc(100vh - 58px)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > div": {
        backgroundColor: "rgba(256, 256, 256, .7)",
        display: "inline-block",
        padding: theme.spacing(7),
        textAlign: "center",
        position: "relative",
        "& span": {},
        "& > h1": {
          fontFamily: `'Great Vibes', cursive`,
          fontWeight: "normal",
          fontSize: 95,
        },
      },
    },
    [theme.breakpoints.down("xl")]: {
      cover: {
        "& > div": {
          padding: theme.spacing(9),
        },
        "& > h1": {
          fontSize: 100,
        },
        "& span": {
          fontSize: 30,
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      cover: {
        "& > div": {
          padding: theme.spacing(7),
        },
        "& > h1": {
          fontSize: 95,
        },
        "& span": {
          fontSize: 20,
        },
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      cover: {
        height: "calc(100vh - 47px)",
        "& > div": {
          padding: theme.spacing(3),
          "& span": {},
          "& > h1": {
            fontSize: 60,
          },
        },
      },
    },
  };
});
