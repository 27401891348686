import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    cardContainer: {
      padding: theme.spacing(1),
    },
    pagination: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(4, 0),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "& img": {
        height: 22,
        position: "absolute",
        bottom: 0,
        marginLeft: theme.spacing(3),
      },
    },
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  };
});
