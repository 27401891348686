import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: grey[900],
      "& img": {
        height: 20,
      },
      "& h1": {
        fontSize: 30,
      },
    },
    [theme.breakpoints.down("xl")]: {
      root: {
        "& img": {
          margin: theme.spacing(2, 0, 0, 0),
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      root: {
        "& img": {
          margin: theme.spacing(1, 0, 0, 0),
        },
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  };
});
