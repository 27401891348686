import React, { FC, useEffect, useState } from "react";
import { useStyles } from "./filter-bar.style";
import { useTranslator } from "localization";
import { CornerPattern, SlickSlider } from "components/shared";
import {
  Badge,
  Button,
  Fade,
  Grid,
  isWidthUp,
  MenuItem,
  Modal,
  Paper,
  Select,
  SwipeableDrawer,
  withWidth,
} from "@material-ui/core";
import arrow from "assets/arrowLeft.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import clsx from "clsx";
import Slider from "react-slick";
import { ICategory, IFilterColor } from "views/items/types";
import { useLocation } from "react-router";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export interface IFilterBar {
  colors?: IFilterColor[];
  categs: ICategory[];
  onColorClick?(color: string[]): void;
  oncategChange?(id?: number): void;
  width: any;
}

const FilterBar: FC<IFilterBar> = ({ colors, onColorClick = () => {}, categs, oncategChange = () => {}, width }) => {
  const lang = useTranslator();
  const classes = useStyles();
  let slider: Slider | null;
  const delayList = [100, 150, 200, 250, 300, 350, 400];
  const [val, setVal] = useState<number | undefined>(0.1);
  const [colorArr, setColorArr] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const link = location.pathname?.split("/")[1];

  const handleSelectChange = (e: any) => {
    setVal(e.target.value);
    const val = e.target.value === 0.1 ? undefined : e.target.value;
    oncategChange(val);
  };

  const handleColorChange = (colr: string) => {
    if (colorArr.includes(colr)) {
      setColorArr(colorArr.filter((c) => c !== colr));
      onColorClick(colorArr.filter((c) => c !== colr));
    } else {
      setColorArr([...colorArr, colr]);
      onColorClick([...colorArr, colr]);
    }
  };

  const clearFilter = () => {
    setColorArr([]);
    onColorClick([]);
    setModalOpen(false);
  };

  const renderMobile = () => {
    return (
      <div className={classes.mobileRoot}>
        {categs && (
          <Grid item xs={12} sm={2} className={classes.selectContainer}>
            <Select
              value={val || ""}
              variant="outlined"
              classes={{ select: classes.select }}
              onChange={handleSelectChange}
            >
              <MenuItem value={0.1}>{lang.allCategs}</MenuItem>
              {categs?.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {link === "wallpaper" && (
          <>
            <Button variant="contained" onClick={() => setModalOpen(true)} className={classes.openButton}>
              <ColorLensIcon />
            </Button>
            <SwipeableDrawer
              open={modalOpen}
              onOpen={() => setModalOpen(true)}
              onClose={() => setModalOpen(false)}
              classes={{ paper: classes.modalPaper }}
              swipeAreaWidth={14}
              anchor="right"
            >
              <Fade in={colorArr.length > 0}>
                <div className={classes.modalButtons}>
                  <Button
                    variant="contained"
                    className={classes.clearFilterButton}
                    disabled={colorArr.length === 0}
                    onClick={clearFilter}
                  >
                    <Badge badgeContent={colorArr.length} color="primary">
                      <HighlightOffIcon />
                    </Badge>
                  </Button>
                </div>
              </Fade>
              <div className={classes.modalColorsContainer}>
                {colors?.map((c, i) => (
                  <div key={i} data-aos-delay={i * 50} data-aos="fade-left" data-aos-duration={300}>
                    <Button
                      className={clsx(classes.badge, colorArr.includes(c.color) && classes.activeBadge)}
                      style={{
                        background: colorArr.includes(c.color) ? colorArr.filter((clr) => clr === c.color)[0] : "#fff",
                      }}
                      onClick={() => handleColorChange(c.color)}
                    >
                      <div style={{ backgroundColor: c.color }} className={clsx(classes.color)}></div>
                      <span className={classes.count}>{c.count}</span>
                    </Button>
                  </div>
                ))}
              </div>
            </SwipeableDrawer>
          </>
        )}
      </div>
    );
  };

  const renderWeb = () => {
    return (
      <Grid container className={classes.root}>
        {colors && (
          <>
            <Grid item xs={1} className={clsx(classes.arrow, classes.leftArrow)}>
              <Button onClick={() => slider?.slickPrev()}>
                <ArrowBackIosIcon />
              </Button>
            </Grid>
            <Grid item xs={8} className={classes.slick}>
              <SlickSlider sliderRef={(r) => (slider = r)}>
                {colors?.map((c, i) => (
                  <div
                    key={i}
                    data-aos-delay={delayList[Math.floor(Math.random() * delayList.length)]}
                    data-aos="slide-down"
                    data-aos-duration={300}
                  >
                    <Button
                      className={clsx(classes.badge, colorArr.includes(c.color) && classes.activeBadge)}
                      style={{
                        background: colorArr.includes(c.color) ? colorArr.filter((clr) => clr === c.color)[0] : "#fff",
                      }}
                      onClick={() => handleColorChange(c.color)}
                    >
                      <div style={{ backgroundColor: c.color }} className={clsx(classes.color)}></div>
                      <span className={classes.count}>{c.count}</span>
                    </Button>
                  </div>
                ))}
              </SlickSlider>
            </Grid>
            <Grid item xs={1} className={clsx(classes.arrow, classes.rightArrow)}>
              <Button onClick={() => slider?.slickNext()}>
                <ArrowForwardIosIcon />
              </Button>
            </Grid>
          </>
        )}
        {categs && (
          <Grid item sm={2} className={classes.selectContainer}>
            <Select
              value={val || ""}
              variant="outlined"
              classes={{ select: classes.select }}
              onChange={handleSelectChange}
            >
              <MenuItem value={0.1}>{lang.allCategs}</MenuItem>
              {categs?.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>
    );
  };

  return isWidthUp("sm", width) ? renderWeb() : renderMobile();
};

export default withWidth()(FilterBar);
