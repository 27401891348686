import { bindAsyncActions } from "helpers/byndAsyncAction";
import { takeEvery } from "redux-saga/effects";
import { ItemActions } from "../actions/item.actions"
import ItemApi from "api/items.api";

export function* ItemSaga() {
    yield takeEvery(ItemActions.getItems, bindAsyncActions(ItemActions.getItemsAsync)(ItemApi.getItems));
    yield takeEvery(ItemActions.getItem, bindAsyncActions(ItemActions.getItemAsync)(ItemApi.getItem));
    yield takeEvery(ItemActions.getColors, bindAsyncActions(ItemActions.getColorsAsync)(ItemApi.getColor));
    yield takeEvery(ItemActions.getMainItems, bindAsyncActions(ItemActions.getMainItemsAsync)(ItemApi.getMainItems))
    yield takeEvery(ItemActions.getCategs, bindAsyncActions(ItemActions.getCategsAsync)(ItemApi.getCategs))
}